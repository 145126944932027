<template lang="pug">
om-modal.generate-modal(
  :name="modalName"
  width="600"
  color="light"
  @closeClicked="closeModal"
  @beforeOpen="beforeOpenModal"
)
  template(#header-title)
    h3 {{ $t('SPR.generate.modal.title') }}

  template(#modal-body)
    i18n.step(path="SPR.generate.modal.amountLabel" :tag="false")
      template(#amount-input)
        om-input#amount-input.w-6.amount-input(
          small
          :min="0"
          type="number"
          :placeholder="$t(`SPR.generate.modal.all`)"
          v-model.number="amount"
        )

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="closeModal") {{ $t('cancel') }}
      om-button.ml-2.confirm-btn(primary @click="generateSPR" :loading="confirmed") {{ $t('SPR.generate.modal.generate') }}
</template>
<script>
  import GENERATE_SPR from '@/graphql/GenerateSPR.gql';

  export default {
    data: () => ({
      modalName: 'spr-generate-modal',
      id: null,
      amount: null,
      confirmed: false,
    }),
    methods: {
      async generateSPR() {
        this.confirmed = true;
        let success;
        try {
          const response = await this.$apollo.mutate({
            mutation: GENERATE_SPR,
            variables: {
              id: this.id,
              requestedItems: this.amount,
            },
          });

          success = !!response?.data?.result?.success;

          if (!success && response?.errors?.length) {
            console.error('Error during generate SPR', { errors: response.errors });
          }
        } catch (err) {
          console.error('Caugth error during generate SPR', { msg: err.message, stack: err.stack });
        }

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('SPR.generate.success'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('SPR.generate.failed'),
          });
        }

        this.$bus.$emit('reloadSPRConfigList');
        this.closeModal();
      },
      closeModal() {
        this.id = null;
        this.amount = null;
        this.confirmed = false;
        this.$modal.hide(this.modalName);
      },
      beforeOpenModal({ params: { id } }) {
        this.id = id;
      },
    },
  };
</script>

<style lang="sass">
  .generate-modal
    .amount-input
      display: inline-block
</style>
